import { Grid } from '@mui/material';
import React, { useState, useEffect } from "react";
import EventCard from "./eventcard";
import { Event } from "../types";
import data from "../Utils/eventsOutput.json"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DropdownMenu from './menubutton';


import Autocomplete from './autocomplete';

interface EventsDisplayState {
  searchValue: string;
  events: Event[];
  selectEvents: Event[];
}

const EventDisplay: React.FC = () => {
  const [state, setState] = useState<EventsDisplayState>({
    searchValue: "",
    events: [],
    selectEvents: [],
  });

  useEffect(() => {
    let jsonData = data; // make sure data is imported
    setState(prevState => ({ ...prevState, events: jsonData, selectEvents: jsonData }));
  }, []);
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleEventFilter = (searchItems: string[]) => {
    // Search events for items in searchItems
    // Update the state with the filtered events

    if (searchItems.length === 0) {
      setState(prevState => ({ ...prevState, selectEvents: state.events }));
      return;
    }

    let filteredEvents = state.events.filter((event) => searchItems.includes(String(event.id)));
    setState(prevState => ({ ...prevState, selectEvents: filteredEvents }));
  };


  return (
    <Grid container>
      <Grid item columns={16} xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
        <Grid xs={2} lg={4} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: isLgScreen ? 'flex-start' : 'left', height: '100%' }}>

          <DropdownMenu />
        </Grid>

        <Grid xs={14} lg={12} sx={{ display: "flex", width: '100%', justifyContent: 'left', alignItems: "center" }}>
          <Autocomplete
            placeholder="Search"
            openOnFocus={true}
            handleEventFilter={handleEventFilter}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ paddingTop: '10px' }} justifyContent="center" >
        {state.selectEvents.map((event) => (
          <EventCard key={event.id} event={event} /> // assuming your event has an 'id' field
        ))}
      </Grid>
    </Grid>
  );
};

export default EventDisplay;
