import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { SmLogo, LgLogo } from './logo';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';




export default function DropdownMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Grid container justifyContent='center' sx={{ paddingRight: 0 }}>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ paddingLeft: 0, paddingRight: 0, height: '100%', width: '100%' }}
            >
                {isLgScreen ? (

                    <LgLogo />

                ) : (
                    <SmLogo />
                )}
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose}>Submit an Event...   <small><i>(Coming soon)</i></small></MenuItem>
                {!isLgScreen && <Divider sx={{ mb: 0 }} />}
                {!isLgScreen && (

                    <MenuItem onClick={handleClose} sx={{ paddingLeft: 1, paddingRight: 1, height: '100%', width: '100%', justifyContent: 'center' }}>
                        <LgLogo padding='0' />
                    </MenuItem>

                )}

            </Menu>
        </Grid>
    );
}