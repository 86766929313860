import Footer from './Components/footer';
import EventDisplay from './Components/eventdisplay';

function App() {
  return (
    <div>
      <EventDisplay />
      <Footer />
    </div>
  );
}

export default App;
