import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { CardActionArea, CardActions, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Link from '@mui/material/Link';
import React, { useState } from "react";
import { Event } from "../types";
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tag, CardSummaryTruncatedText } from '../Utils/helpers';
import { AddToCalendarButton } from './addtocalendar';
import monthColors from '../Utils/monthColors';



type EventCardProps = {
    event: Event;
};

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}


const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const EventCard: React.FC<EventCardProps> = ({ event }) => {
    const [expanded, setExpanded] = useState(false);



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Convert the start date to a luxon date
    const eventStartDate = DateTime.fromISO(event.startDate);

    let formattedStartDate = eventStartDate.toFormat('MM-dd-yyyy HH:mm');
    // if eventStartDate time is 00:00:00, then set the time to 12:00:00
    if (eventStartDate.toFormat('HH:mm:ss') === '00:00:00') {
        formattedStartDate = eventStartDate.toFormat('MM-dd-yyyy');
    }




    // get the event day and event month from formattedStartDate
    const eventDay = formattedStartDate.slice(3, 5);
    let monthNumber = parseInt(formattedStartDate.slice(0, 2))
    let month = monthNumber as keyof typeof monthColors;
    // let month: keyof typeof monthColors

    return (
        <Grid container item xs={12} sm={6} md={4} lg={3} xl={2} justifyContent="center" alignItems="center" ml={1} mr={1}>
            <Card sx={{ width: "100%", maxWidth: 345, height: '95%', display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                    <CardHeader
                        avatar={
                            < Avatar sx={{ bgcolor: monthColors[month] }} aria-label="event">
                                {eventDay}
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={`${event.source} event`}
                        subheader={formattedStartDate}
                    />
                </Grid>
                <Box sx={{ maxHeight: '80vh', overflow: expanded ? 'auto' : 'hidden', paddingBottom: '5px' }}>
                    <Grid item>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    <CardSummaryTruncatedText>{event.summary}</CardSummaryTruncatedText>
                                </Typography>
                                <Grid xs container display="flex" justifyContent="right" alignContent='center'>
                                    {event.tags.map((tag, tagIndex) => {
                                        return <Tag key={tagIndex} name={tag} />
                                    })}
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Grid>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            {/* <Typography paragraph>Description:</Typography> */}
                            <Typography paragraph>
                                {event.description ? event.description : <i>No description available.</i>}
                            </Typography>
                            {event.location && <Typography paragraph>Location: {event.location}</Typography>}
                            {event.url && <Typography><Link href={event.url}>Event Link</Link></Typography>}
                        </CardContent>
                    </Collapse>
                </Box>

                <Grid item sx={{ marginTop: 'auto' }}>
                    <CardActions>
                        <AddToCalendarButton eventData={event} />
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>
                </Grid>

            </Card >
        </Grid >
    );
};

export default EventCard;

