import { createUniversalLink, isMobile } from '../Utils/helpers';
import React from 'react';
import { Button } from '@mui/material';
import { Event } from "../types";
const ICAL = require("ical.js");


interface AddToCalendarButtonProps {
    eventData: Event;
}

export const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = ({ eventData }) => {
    const handleAddToCalendar = () => {
        const dateInMs = Date.now();
        const uid = `${eventData.id}-${dateInMs % 1e8}`
        // Convert the event data to iCalendar format
        const icsFile = generateICSFile(eventData, uid);

        if (isMobile()) {
            const url = createUniversalLink(eventData);
            const link = document.createElement('a');
            link.href = url;
            link.click();
            URL.revokeObjectURL(url);

        } else {
            const blob = new Blob([icsFile], { type: 'text/calendar' });
            const url = URL.createObjectURL(blob);
            // Create a link element and click it programmatically to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = `esevent-${uid}.ics`;
            link.click();
            URL.revokeObjectURL(url);
        }
    };

    function generateICSFile(eventData: Event, uid: string) {
        const event = new ICAL.Event();
        const startDate = new Date(eventData?.startDate);

        if (eventData.endDate) {
            const endDate = new Date(eventData.endDate);
            event.endDate = ICAL.Time.fromJSDate(endDate, true);
        }

        event.uid = `${uid}@easternsierra.app`;
        event.startDate = ICAL.Time.fromJSDate(startDate, true);
        event.summary = String(eventData.summary);

        if (eventData.location) {
            event.location = String(eventData.location);
        }

        if (eventData.description) {
            event.description = `Eastern Sierra Events\n\nSource: ${eventData.source}\n${eventData.description}\n\nFind more events at: https://www.easternsierra.app`;
        } else {
            event.description = `Source: ${eventData.source}\nFind more events at: https://www.easternsierra.app`;
        }

        const calendar = new ICAL.Component(['vcalendar', [], []]);
        calendar.addSubcomponent(event.component);

        return calendar.toString();
    }

    return (
        <Button size="small" color="primary" onClick={handleAddToCalendar}>Add to Calendar</Button>
    );
};

export default AddToCalendarButton;
