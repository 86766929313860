import {  red ,pink ,deepPurple ,indigo ,blue ,lightBlue ,cyan ,teal ,green ,yellow ,orange ,blueGrey } from '@mui/material/colors';


const monthColors = {
    1: red[900],
    2: pink[900],
    3: deepPurple[800],
    4: indigo[800],
    5: blue[800],
    6: lightBlue[800],
    7: cyan[800],
    8: teal[800],
    9: green[800],
    10: yellow[800],
    11: orange[700],
    12: blueGrey[800],
};
// const monthColors = {
//     1: "#8a4f7d",
//     2: "#887880",
//     3: "#88A096",
//     4: "#BBAB8B",
//     5: "#EF8275",
//     6: "#75436A",
//     7: "#776970",
//     8: "#779287",
//     9: "#AC9972",
//     10: "#EC695B",
//     11: "#4E2D47",
//     12: "#564D52",
// };

export default monthColors;
