// Event display component
// This component is responsible for displaying the events in the calendar
// It is also responsible for the event creation and deletion
// It is also responsible for the event editing
import { Box, Link, Stack, Tooltip } from "@mui/material";
import {
  FileCodeIcon,
  MentionIcon,
  SponsorTiersIcon,
} from "@primer/octicons-react";
import React from "react";


// This is the event display component
// It is responsible for displaying the events in the calendar

export default class Footer extends React.Component {



  // The render function of the component
  render() {
    return (
      <div>
        <Box component="footer" sx={{ py: 4 }}>
          <Stack spacing={4} direction="row" justifyContent="center">
            <Tooltip title="Contact Me">
              <Link
                href="mailto:info@easternsierra.app"
                color="textPrimary"
                aria-label="Contact Me"
                target="_blank"
                rel="noopener"
              >
                <MentionIcon size="small" verticalAlign="middle" />
              </Link>
            </Tooltip>
            <Tooltip title="Source Code">
              <Link
                href="https://github.com/cloudripper/es-events"
                color="textPrimary"
                aria-label="Source Code"
                target="_blank"
                rel="noopener"
              >
                <FileCodeIcon size="small" verticalAlign="middle" />
              </Link>
            </Tooltip>
            <Tooltip title="Support Me">
              <Link
                href="www.easternsierra.app"
                color="textPrimary"
                aria-label="Support Me"
                target="_blank"
                rel="noopener"
              >
                <SponsorTiersIcon size="small" verticalAlign="middle" />
              </Link>
            </Tooltip>
          </Stack>
        </Box>
      </div>
    );
  }
}