import React from 'react';
import { Grid } from '@mui/material';

type LgLogoProps = {
    padding?: string;
};

export const SmLogo: React.FC = () => {
    return (
        <div
            style={{
                // backgroundColor: '#a8c1c5',
                borderRadius: '8px',
                width: '48px',
                height: '48px',
                paddingRight: '8px',
            }}
        >
            <img
                src={process.env.PUBLIC_URL + '/logo.png'}
                alt="Logo"
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
            />
        </div>
    );
};

export const LgLogo: React.FC<LgLogoProps> = ({ padding = '18px' }) => {
    return (

        <Grid
            sx={{
                // backgroundColor: '#a8c1c5',
                borderRadius: '8px',
                width: '100%',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: padding,
            }}
        >
            <img
                src={process.env.PUBLIC_URL + '/logo-lg.svg'}
                alt="Logo"
                style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '8px' }}
            />
        </Grid>
    );
};

