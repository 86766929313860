import React, { MouseEvent, TouchEvent, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { Tag } from '../Utils/helpers';


// AutoComplete Imports
import {
    AutocompleteOptions,
    AutocompleteState,
    createAutocomplete,
} from '@algolia/autocomplete-core';
import { Hit } from '@algolia/client-search';


// Material UI Imports
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Unstable_Grid2';

// Material UI Icons
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';

import { DateTime } from 'luxon';
import data from "../Utils/eventsOutput.json"



type AutocompleteItem = Hit<{
    objectID: string;
    source: string;
    summary: string;
    startDate: string;
    endDate: string | undefined | null;
    description: string | undefined | null;
    location: string | undefined | null;
    url: string | undefined | null;
    tags: string[];
}>;

interface AutocompleteProps extends Partial<AutocompleteOptions<AutocompleteItem>> {
    handleEventFilter: (event: string[]) => void;
}

export default function Autocomplete(props: AutocompleteProps) {
    const { handleEventFilter } = props;

    const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<AutocompleteItem>>({
        collections: [],
        completion: null,
        context: {},
        isOpen: false,
        query: '',
        activeItemId: null,
        status: 'idle',
    });



    const [selectedItems, setSelectedItems] = useState<string[]>([]);


    const autocomplete = React.useMemo(
        () =>
            createAutocomplete<
                AutocompleteItem,
                React.BaseSyntheticEvent,
                React.MouseEvent,
                React.KeyboardEvent
            >({
                onStateChange({ state }) {
                    setAutocompleteState(state);
                },
                insights: false,

                // The `getSources` function returns source data
                getSources() {
                    return [
                        {
                            sourceId: 'eventData',
                            getItems({ query }) {
                                return data.filter(({ summary }) =>
                                    summary.toLowerCase().includes(query.toLowerCase())
                                ).map(item => ({
                                    ...item,
                                    objectID: String(item.id),
                                }));
                            },
                            getItemUrl({ item }) {
                                if (item.url) {
                                    return item.url;
                                } else {
                                    return "";
                                }
                            },
                        },
                    ];
                },
                ...props,
            }),
        [props]
    );
    const inputRef = React.useRef<HTMLInputElement>(null);
    const formRef = React.useRef<HTMLFormElement>(null);
    const panelRef = React.useRef<HTMLDivElement>(null);
    const { getEnvironmentProps } = autocomplete;

    React.useEffect(() => {
        if (!formRef.current || !panelRef.current || !inputRef.current) {
            return undefined;
        }

        const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
            formElement: formRef.current,
            inputElement: inputRef.current,
            panelElement: panelRef.current,
        });

        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('touchstart', onTouchStart);
        window.addEventListener('touchmove', onTouchMove);

        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('touchstart', onTouchStart);
            window.removeEventListener('touchmove', onTouchMove);
        };
    }, [getEnvironmentProps, autocompleteState.isOpen]);



    const submitSearch = (events: string[]) => {
        setAutocompleteState({
            ...autocompleteState,
            query: '',
            isOpen: false
        });

        if (events !== selectedItems) {
            setSelectedItems(events)
        }
        handleEventFilter(events)
    }

    const handleBulkSearch = (events: AutocompleteItem[]) => {
        const objectIDs = events.map((item) => item.objectID);
        console.log(objectIDs);
        submitSearch(objectIDs)
    }

    const handleSearchItemClick = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
        event.preventDefault();
        const itemId = event.currentTarget.id;
        submitSearch([itemId])

    };


    return (
        <Grid container xs={12} sm={8} md={6} display="flex" justifyContent="center" alignItems="center" {...autocomplete.getRootProps({})}>
            <Paper
                ref={formRef}
                {...autocomplete.getFormProps({
                    inputElement: inputRef.current,
                    onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                    }
                })}
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
            >
                <IconButton
                    className="aa-SubmitButton"
                    type="submit"
                    title="Submit"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={() => {
                        if (autocompleteState.collections[0].items.length === 0) {
                            submitSearch(selectedItems)
                        } else {
                            handleBulkSearch(autocompleteState.collections[0].items)
                        }
                    }}>
                    <SearchIcon />
                </IconButton>

                <InputBase
                    sx={{ ml: 1, flex: 1, }}
                    inputProps={{ 'aria-label': 'Search Events' }}
                    ref={inputRef}
                    {...autocomplete.getInputProps({
                        inputElement: inputRef.current,
                        placeholder:
                            selectedItems.length > 0
                                ? `${selectedItems.length} ${selectedItems.length === 1 ? 'Result' : 'Results'}`
                                : 'Search Events',
                        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.key === 'Enter') {
                                // Perform search here
                                event.preventDefault(); // Prevent form submission if the input is inside a form
                                // Call your search function or perform search logic
                                handleBulkSearch(autocompleteState.collections[0].items)
                            }
                            if (event.key === 'Backspace' || event.key === 'Delete') {
                                if (autocompleteState.query === '' && selectedItems.length > 0 && !autocompleteState.isOpen) {
                                    setSelectedItems([]);
                                    handleEventFilter([]);
                                }
                            }
                        }
                    })}
                />
                {(autocompleteState.isOpen || selectedItems.length > 0) && (
                    <IconButton className="aa-SubmitButton" title="Clear" onClick={() => {
                        autocomplete.setQuery('')
                        autocomplete.setIsOpen(false);
                        if (selectedItems.length > 0) {
                            setSelectedItems([]);
                            handleEventFilter([]);
                        }
                    }} sx={{ p: '10px' }} aria-label="search">
                        <ClearIcon />
                    </IconButton>
                )}

            </Paper>

            {autocompleteState.isOpen && (
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        bgcolor: 'background.paper',
                        opacity: autocompleteState.status === 'stalled' ? '35%' : '100%'
                    }}
                >
                    <FixedSizeList
                        style={{ marginTop: '1px', marginBottom: '10px', }}
                        height={400}
                        width={'100%'}
                        itemSize={46}

                        itemCount={autocompleteState.collections.length}
                        overscanCount={5}
                    >
                        {({ index, style }) => {
                            const collection = autocompleteState.collections[index];
                            const { items } = collection;

                            return (
                                <ListItem sx={{
                                    maxWidth: '100%',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '2px'
                                }} key={index} component="div" disablePadding>
                                    {items.map((item, itemIndex) => {
                                        const date = DateTime.fromISO(item.startDate);
                                        const formattedStartDate = date.toFormat('ccc, MMMM dd kkkk');
                                        return (
                                            <Grid container xs={12} display="flex" justifyContent="center" alignItems="center" key={`item-${itemIndex}`}>
                                                {(itemIndex !== 0) && (
                                                    <Grid xs={12}>
                                                        <Divider sx={{ backgroundColor: 'lightgrey', }} />
                                                    </Grid>
                                                )}
                                                <Grid container xs={12} display="flex" justifyContent="center" alignItems="center" >
                                                    <ListItemButton
                                                        sx={{
                                                            paddingLeft: '4px',
                                                            width: '100%',
                                                            fontStyle: 'italic',
                                                            '&:hover': {
                                                                backgroundColor: 'grey.200',
                                                            },
                                                            ...(selectedItems.includes(item.objectID) && {
                                                                backgroundColor: 'grey.400',
                                                            }),
                                                        }}
                                                        onClick={handleSearchItemClick}
                                                        // onTouchStart={handleTouchStart}
                                                        id={item.objectID} >
                                                        <Grid>
                                                            <ArrowRightTwoToneIcon />
                                                        </Grid>
                                                        <Grid xs={8} sx={{ paddingLeft: '2%', paddingRight: '2%' }}>
                                                            <ListItemText primary={item.summary} secondary={formattedStartDate} />
                                                        </Grid>

                                                        <Grid xs container display="flex" justifyContent="right" alignContent='center'>
                                                            {item.tags.map((tag, tagIndex) => {
                                                                return <Tag key={tagIndex} name={tag} />
                                                            })}
                                                        </Grid>
                                                    </ListItemButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </ListItem>
                            );
                        }}
                    </FixedSizeList>
                </Paper>
            )
            }

        </Grid >
    );
}
