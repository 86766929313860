import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Event } from "../types";


export const CardSummaryTruncatedText = styled('div')({
    display: '-webkit-box',
    WebkitLineClamp: 6, // Changed to 6
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const TagTruncatedText = styled('div')({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    WebkitLineClamp: 2,
    msFlexDirection: 'column',
    flexDirection: 'column',
    WebkitBoxPack: 'center',
    WebkitJustifyContent: 'center',
    msFlexPack: 'center',
    justifyContent: 'center',
})


interface TagProps {
    name: string;
    color?: string;
}

// Tag Component
export const Tag: React.FC<TagProps> = ({ name, color = 'purple' }) => (
    <Grid sx={{ marginLeft: 1, marginRight: 1 }}><Button
        sx={{ fontSize: '9px', maxWidth: '64px', padding: '2px', color: color }}
        color="secondary"
        disabled={false}
        size="small"
        variant="outlined"
    >
        <TagTruncatedText style={{ color: color, borderColor: color }}>
            {name}
        </TagTruncatedText>
    </Button></Grid>
)

export function createUniversalLink(event: Event): string {
    const baseLink = 'https://www.google.com/calendar/render?action=TEMPLATE';

    const text = encodeURIComponent(event.summary);
    const details = encodeURIComponent(event.description ? event.description : "");

    // Here we assume the endDate is same as the startDate when the endDate is null.
    // You might need to adjust this based on your requirement.
    const startDateTime = encodeURIComponent(event.startDate.replace(/-|:|\.\d\d\d/g, ""));
    const endDateTime = event.endDate
        ? encodeURIComponent(event.endDate.replace(/-|:|\.\d\d\d/g, ""))
        : startDateTime;

    let link = `${baseLink}&text=${text}&dates=${startDateTime}/${endDateTime}&details=${details}`;

    if (event.location) {
        link += `&location=${encodeURIComponent(event.location ? event.location : "")}`;
    }

    return link;
}

export function isMobile(): boolean {
    const userAgent = (navigator.userAgent || navigator.vendor || !(window as any).opera) as string;
    if (/windows phone/i.test(userAgent)) {
        return true;
    }
    if (/android/i.test(userAgent)) {
        return true;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return true;
    }
    return false;
}